import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBrandPolicy } from '~/schemes';
import { HIconLabel } from '~/components';

export interface MyBrandPolicyProps {
  policy: HotelBrandPolicy;
}

export interface MyBrandPolicyEmits {}

export interface MyBrandPolicyScopedSlots {}

@Component<MyBrandPolicyRef>({
  name: 'MyBrandPolicy',
  render() {
    const { title, description, items } = this.policy;

    return (
      <div staticClass="my-policy">
        <div staticClass="my-policy__inner">
          <h2 staticClass="my-policy__title">{title}</h2>
          <div staticClass="my-policy__description">{description}</div>
          <ul staticClass="my-policy__items">
            {items.map((policyItem, policyItemIndex) => (
              <li staticClass="my-policy__item" key={policyItemIndex}>
                <div staticClass="my-policy__item__header">
                  <div staticClass="my-policy__item__header__icon">
                    <img
                      staticClass="my-policy__item__image"
                      src={this.$res.img(policyItem.image.url)}
                      alt={policyItem.image.alt}
                    />
                  </div>
                  <div staticClass="my-policy__item__header__body">
                    <h4 staticClass="my-policy__item__title">
                      {policyItem.title}
                    </h4>
                    <div staticClass="my-policy__item__sub-title">
                      {policyItem.subTitle}
                    </div>
                  </div>
                </div>
                <div staticClass="my-policy__item__body">
                  {policyItem.items.map((item, index) => (
                    <HIconLabel
                      staticClass="my-policy__item__body__item"
                      name="check-circle"
                      key={index}>
                      <span staticClass="my-policy__item__body__item__text">
                        {item}
                      </span>
                    </HIconLabel>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  },
})
export default class MyBrandPolicyRef
  extends Vue
  implements MyBrandPolicyProps {
  @Prop({ type: Object, required: true }) readonly policy!: HotelBrandPolicy;
}

export const TypedMyBrandPolicy = tsx
  .ofType<MyBrandPolicyProps, MyBrandPolicyEmits, MyBrandPolicyScopedSlots>()
  .convert(MyBrandPolicyRef);
